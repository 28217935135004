import React, { useEffect, useState, useRef } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import ReactStars from "react-rating-stars-component";
import BeatLoader from "react-spinners/BeatLoader"
import axios from "axios";

import "./App.css";

function App() {

  const [isAuth, setIsAuth] = useState(false);
  let authRequestSent = false

  const [isLoading, setIsLoading] = useState(false);
  const [readyToPlay, setReadyToPlay] = useState(false);
  const [playTriggered, setPlayTriggered] = useState(false);

  // Login form
  const [form, setForm] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const [otpForm, setOTPForm] = useState(false);
  const [otp, setOtp] = useState("");
  const [showOTPError, setShowOTPError] = useState("");
  const [sendingOTP, setSendingOTP] = useState(false);

  // Help modal
  const [showhelp, setShowHelp] = useState(false);

  // Feedback
  const [showFeedback, setShowFeedback] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(true);
  const [showFeedbackError, setShowFeedbackError] = useState("");
  const [submittingFeedback, setSubmittingFeedback] = useState(false);
  const [successFeedback, setSuccessFeedback] = useState(false);

  const checkStatus = async (emailAddress) => {
    authRequestSent = true;
    try {
      const response = await axios.post(
        "https://api.elvis.xrvizion.com/user/authorize",
        { email: emailAddress }
        , { withCredentials: true }
      );
      if (response.data.isUserAuthorized) {
        setIsAuth(true)
      }
      else {
        setIsAuth(false)
      }

      setIsLoading(false)
    } catch (error) {
      // console.error(error);
      setIsAuth(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    let emailAddress = localStorage.getItem('email');
    if (emailAddress && !authRequestSent) {
      setIsLoading(true)
      checkStatus(emailAddress)
    }
    // window.addEventListener('resize', function () {
    //   window.location.reload();
    // });
    document.addEventListener('keydown', function (event) {
      // Check if the feedback form is currently open
      if (showFeedback) {
        // Prevent the default behavior of the keydown event
        event.preventDefault();
      }
    });
  }, [])

  const isMobile = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      // return window.innerWidth < 1000;
      return false;
    }
  }

  const isMobileDevice = isMobile();

  const { unityProvider, loadingProgression, isLoaded, sendMessage, UNSAFE__unityInstance } = useUnityContext({
    loaderUrl: isMobileDevice ? 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Elvis-Presley-Mobile-Responsiveness/Build/elvis-presley-mobile.loader.js' : 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Elvis-Presley-Responsiveness/Build/elvis-presley.loader.js',
    dataUrl: isMobileDevice ? 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Elvis-Presley-Mobile-Responsiveness/Build/elvis-presley-mobile.data.br' : 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Elvis-Presley-Responsiveness/Build/elvis-presley.data.br',
    frameworkUrl: isMobileDevice ? 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Elvis-Presley-Mobile-Responsiveness/Build/elvis-presley-mobile.framework.js.br' : 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Elvis-Presley-Responsiveness/Build/elvis-presley.framework.js.br',
    codeUrl: isMobileDevice ? 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Elvis-Presley-Mobile-Responsiveness/Build/elvis-presley-mobile.wasm.br' : 'https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Elvis-Presley-Responsiveness/Build/elvis-presley.wasm.br',
  })

  useEffect(() => {
    if (UNSAFE__unityInstance) {
      window.unityInstance = UNSAFE__unityInstance
    }
  }, [UNSAFE__unityInstance])


  useEffect(() => {
    if (isLoaded) {
      setTimeout(() => {
        setReadyToPlay(true);
      }, 2000);
    }
  }, [isLoaded])

  useEffect(() => {
    if (readyToPlay && playTriggered) {
      const canvas = document.querySelector("canvas");
      if (canvas) {
        canvas.style.display = "block";
        setShowHelp(true)
      }
      setIsLoading(false);

      const getFeedbackStatus = async () => {
        try {
          let emailID = localStorage.getItem("email");
          const response = await axios.post(
            "https://api.elvis.xrvizion.com/user/getfeedback",
            { email: emailID }
          );
          setFeedbackSubmitted(response.data.isFeedbackSet);
        } catch (error) {
          // console.error(error);
        }
      };

      getFeedbackStatus();
    }

  }, [readyToPlay, playTriggered])

  const handleBeforeUnloadRef = useRef();

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!feedbackSubmitted) {
        e.preventDefault();
        e.returnValue = "";
        setShowFeedback(true);
        sendMessage('InputHandler', 'SetCaptureInput', 'false');
      }
    };
    handleBeforeUnloadRef.current = handleBeforeUnload;
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [feedbackSubmitted]);

  const handleButtonClick = () => {
    setIsLoading(true);

    if (isAuth) {
      setPlayTriggered(true);
    } else {
      setForm(true);
    }
  };

  const sendOTP = async (e) => {
    e.preventDefault();
    setShowError("")

    try {
      setSendingOTP(true);
      const response = await axios.post("https://api.elvis.xrvizion.com/user/setuser", { name, email }, { withCredentials: true });
      // console.log(response)
      if (response.data.msg === "Success") {
        setOTPForm(true);
      } else {
        // Handle error response
        setShowError(response.data.msg);
      }
      setSendingOTP(false);
    }
    catch (error) {
      // // Handle network error
      // console.log(error.response.data.msg);
      setShowError(error.response.data.msg);
      setSendingOTP(false);
    }

  }

  const verifyOTP = async (e) => {
    e.preventDefault();

    try {
      setSubmitting(true)
      const response = await axios.post("https://api.elvis.xrvizion.com/user/login", { email, verificationToken: otp }, { withCredentials: true });
      // console.log(response)
      if (response.status === 200) {
        // console.log(response)
        setForm(false);
        setPlayTriggered(true);
        localStorage.setItem("email", email);
        localStorage.setItem("name", name);
      } else {
        // Handle error response
        if (response.status === 401) {
          setShowOTPError("Login code invalid. Please try again.");
        } else {
          setShowOTPError(response.data.msg);
        }
      }
      setSubmitting(false);
    } catch (error) {
      // Handle network error
      // console.log(error.response.data.msg);
      if (error.response.status === 401) {
        setShowOTPError("Login code invalid. Please try again.");
      } else {
        setShowOTPError(error.response.data.msg);
      }
      setSubmitting(false);
    }
  }

  // Utility functions
  const closeHelp = () => {
    setShowHelp(false);
  }

  const ratingChanged = (newRating) => {
    setRating(newRating)
  }

  const submitFeedback = async () => {
    setShowFeedbackError("");
    setSubmittingFeedback(true)
    try {
      let emailID = localStorage.getItem("email");
      const response = await axios.post(
        "https://api.elvis.xrvizion.com/user/setfeedback",
        { email: emailID, message: feedback, stars: rating },
        { withCredentials: true }
      );
      if (response.data.msg === "Success") {
        // Remove beforeunload event listener
        window.removeEventListener("beforeunload", handleBeforeUnloadRef.current);
        // Reload the page
        // window.location.reload();
        setShowFeedback(false);
        sendMessage('InputHandler', 'SetCaptureInput', 'true');
        setSuccessFeedback(true);
      } else {
        // Handle error
        setShowFeedbackError(response.data.msg);
      }
      setSubmittingFeedback(false)
    } catch (error) {
      // Handle network error
      setShowFeedbackError(error.message);
      setSubmittingFeedback(false)
    }
  };

  const setContainerHeights = () => {
    const container = document.querySelector('.container');

    const viewportHeight = window.innerHeight;
    container.style.height = `${viewportHeight * 1}px`;
  };

  useEffect(() => {
    window.addEventListener('resize', setContainerHeights);
    setContainerHeights();

    return () => {
      window.removeEventListener('resize', setContainerHeights);
    };
  }, []);

  return (
    <div className="container">

      <img className="background-image" src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Resources/background.jpg" alt="Background" />
      <div className="overlay"></div>

      {/* LOGIN */}
      {form ?
        otpForm
          ?
          <form className="form" onSubmit={verifyOTP}>
            <div className="form-header" style={{ textAlign: "center" }}>
              <div className="ff-m fs-sm" style={{ wordSpacing: "0.2rem", letterSpacing: "0.03rem" }}>ENTER LOGIN CODE</div>
              <div className="ff-l fs-xxs" style={{ wordSpacing: "0.05rem", letterSpacing: "0.03rem", marginTop: "0.5rem" }}>We've sent a code to {email}</div>
            </div>
            <label>
              <input required style={{ letterSpacing: "0.03rem" }} className="ff-m fs-xxs" type="text" name="name" placeholder="__     __     __     __     __     __" maxLength={6} autocomplete="off" value={otp} onChange={(e) => { setOtp(e.target.value); setShowOTPError("") }} />
              {showOTPError && <span className="ff-l fs-xxs" style={{ color: "white", marginLeft: "0.3rem", opacity: 0.5, letterSpacing: "0.02rem", wordSpacing: "0.03rem", textAlign: "center" }}>{showOTPError}</span>}
            </label>
            <button style={{ letterSpacing: "0.03rem", width: "auto", padding: "0.5rem 4rem" }} disabled={submitting} type="submit" className="button-alt ff-m fs-xs">
              {
                submitting
                  ?
                  <BeatLoader
                    color="#fff"
                    size={"0.35vw"}
                    speedMultiplier={1}
                  />
                  :
                  "CONTINUE"
              }
            </button>
          </form>
          :
          <form className="form" onSubmit={sendOTP}>
            <div className="form-header ff-m fs-sm" style={{ wordSpacing: "0.2rem", letterSpacing: "0.03rem" }}>WELCOME TO SUNDERLAND EMPIRE!</div>
            <label>
              {/* NAME */}
              <input required style={{ letterSpacing: "0.03rem" }} className="ff-m fs-xxs" type="text" name="name" placeholder="NAME" autocomplete="off" value={name} onChange={(e) => setName(e.target.value)} />
            </label>
            <label>
              {/* EMAIL */}
              <input required style={{ letterSpacing: "0.03rem" }} className="ff-m fs-xxs" type="email" name="email" placeholder="EMAIL" autocomplete="off" value={email} onChange={(e) => { setEmail(e.target.value); setShowError("") }} />
              {showError && <span className="ff-l fs-xxs" style={{ color: "white", marginLeft: "0.3rem", opacity: 0.5, letterSpacing: "0.02rem", wordSpacing: "0.03rem" }}>{showError}</span>}
            </label>
            <button style={{ letterSpacing: "0.03rem", width: "auto", padding: "0.5rem 4rem" }} disabled={sendingOTP} type="submit" className="button-alt ff-m fs-xs">
              {
                sendingOTP
                  ?
                  <BeatLoader
                    color="#fff"
                    size={"0.35vw"}
                    speedMultiplier={1}
                  />
                  :
                  "CONTINUE"
              }
            </button>
          </form>
        :
        isLoading ?
          <button className="button fs-xs">
            <div className="ff-m" style={{ letterSpacing: "0.02rem" }}>
              LOADING <br />{Math.round(loadingProgression * 100)}%
            </div>
          </button>
          :
          <button className="button fs-xs" onClick={handleButtonClick}>
            <div className="ff-m" style={{ letterSpacing: "0.03rem" }}>ENTER <br /> SUNDERLAND <br /> EMPIRE</div>
          </button>
      }


      {/* UNITY APP */}
      {playTriggered && <Unity unityProvider={unityProvider} />}

      {/* LOGO */}
      <div className="logo">
        <a href="https://xrcouture.com/">
          <img src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/xrcnew.webp" alt="" />
        </a>
      </div>


      {/* HELP MODAL */}
      {showhelp &&
        <div className="how-to-play" style={{ color: "white" }}>
          <div className="htp-images" style={{ textAlign: isMobileDevice ? "center" : "unset", transform: isMobileDevice ? "translateX(-0.3rem)" : "unset" }}>
            {
              isMobileDevice ?
                <>
                  <div className="htp-images-cont">
                    <img className="htp-image" style={{ width: "5rem" }} src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Resources/hand-move.png" alt="move" />
                  </div>
                </>
                :
                <>
                  <div className="htp-images-cont">
                    <img className="htp-image" style={{ width: "6rem", marginTop: "1rem" }} src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Resources/keys2.png" alt="keys" />
                    <div className="ff-l fs-xxs">Keyboard</div>
                  </div>
                  <div className="htp-images-cont">
                    <img className="htp-image" style={{ width: "2.4rem" }} src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Metadrip/mouse1.png" alt="keys" />
                    <div className="ff-l fs-xxs">Mouse</div>
                  </div>
                </>
            }
          </div>

          <div className="htp-content" style={{ color: "white", marginTop: "1.5rem", textAlign: "center" }}>
            <div className="htp-header ff-m fs-s" style={{ letterSpacing: "0.03rem" }}>HOW TO MOVE</div>
            <div className="htp-info ff-l fs-s" style={{ marginTop: "1rem", letterSpacing: "0.03rem" }}>{isMobileDevice ? "Double tap anywhere to move. Drag on the screen to change the view." : "Use WASD / arrow keys on your keyboard or double-click with the mouse to move. Click and drag the mouse to change the view."}</div>
            <button onClick={closeHelp} className="button-alt ff-m fs-s htp-close" style={{ width: "auto", padding: "0.5rem 3rem", letterSpacing: "0.03rem" }}>Close</button>
          </div>
        </div>
      }

      {
        (playTriggered && readyToPlay) &&
        <>
          <button onClick={() => setShowHelp(true)} className="overlay-button fs-m ff-m"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <img className="overlay-btn-img-1" src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Resources/help-1.png" alt="" />

          </button>
          <button
            className="overlay-button second-btn fs-m ff-m"
            style={{
              right: "5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            onClick={() => {
              window.open("https://linktr.ee/xrvizion", "_blank");
            }}
          >
            <img className="overlay-btn-img-2" src="https://xrv-xrc.s3.ap-south-1.amazonaws.com/Elvis_Presley/Resources/info-1.png" alt="" />

          </button>
        </>
      }


      {/* FEEDBACK */}
      {
        showFeedback
        &&
        <div className="feedback-container" style={{ color: "white" }}>
          <div className="ff-m fs-sm" style={{ letterSpacing: "0.03rem", marginBottom: "0.5rem" }}>
            TELL US WHAT YOU THINK
          </div>

          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={window.innerWidth >= 1200 ? "70" : window.innerWidth >= 768 ? "50" : "40"}
            activeColor="#ffd700"
            // isHalf={true}
            color={"rgba(255, 255, 255, 0.3)"}
          />

          <div className="feedback" style={{ marginTop: "2rem" }}>
            <textarea rows={8} required style={{ letterSpacing: "0.03rem", width: "100%", resize: 'none' }} className="ff-m fs-xs" type="textarea" name="textarea" placeholder="HOW CAN WE IMPROVE?" value={feedback} autocomplete="off" onChange={(e) => setFeedback(e.target.value)} />
            <button onClick={submitFeedback} className="button-alt ff-m fs-sm">{submittingFeedback ?
              <BeatLoader
                color="#fff"
                size={"0.35vw"}
                speedMultiplier={1}
              /> : "SUBMIT"}
            </button>
          </div>

          {showFeedbackError && <span className="ff-l fs-xxs" style={{ color: "white", marginLeft: "0.3rem", marginTop: "0.75rem", opacity: 0.5, letterSpacing: "0.02rem", wordSpacing: "0.03rem" }}>{showFeedbackError}</span>}
        </div>
      }

      {
        successFeedback
        &&
        <div className="feedback-container" style={{ color: "white" }}>
          <div className="ff-m fs-sm" style={{ letterSpacing: "0.03rem", marginBottom: "0.5rem" }}>
            FEEDBACK RECEIVED!
          </div>

          <div className="ff-l fs-xxs" style={{ wordSpacing: "0.05rem", letterSpacing: "0.03rem", marginTop: "0.5rem", textAlign: "center" }}>Thank you for taking the time to share your feedback with us. We appreciate your support.</div>

          <div className="feedback" style={{ marginTop: "3rem" }}>
            <button onClick={() => { setSuccessFeedback(false); window.location.reload(); }} style={{ marginTop: "0rem" }} className="button-alt ff-m fs-sm">CLOSE</button>
          </div>
        </div>
      }

    </div >
  );
}

export default App;